<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-alert
          v-model="showDismissibleAlert"
          v-id="showDismissibleAlert == true"
          v-height-fade
          dismissible
          fade
          class="mb-2"
          variant="danger"
        >
          <div class="alert-body">
            <span>{{ messages }}</span>
          </div>
        </b-alert>
        <ValidationObserver v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(save)">
            <b-row>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="No NIK KTP"
                  rules="required|numeric|digits:16"
                >
                  <b-form-group>
                    <label label-for="nik">
                      No NIK KTP <span class="fs-6 text-danger">*</span>
                    </label>
                    <b-form-input
                      id="mc-name"
                      v-model="model.nik"
                      type="number"
                      placeholder="Masukkan No NIK KTP"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Nama"
                  rules="required"
                >
                  <b-form-group>
                    <label label-for="name">
                      Nama <span class="fs-6 text-danger">*</span>
                    </label>
                    <b-form-input
                      id="mc-name"
                      v-model="model.name"
                      placeholder="Masukkan Nama"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Tempat lahir"
                  rules=""
                >
                  <b-form-group label="Tempat Lahir" label-for="mc-name">
                    <b-form-input
                      id="mc-name"
                      v-model="model.pob"
                      placeholder="Masukkan Tempat Lahir"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Tanggal lahir"
                  rules=""
                >
                  <b-form-group>
                    <label label-for="dob">Tanggal Lahir </label>
                    <date-picker
                      v-model="model.dob"
                      :firstDayOfWeek="1"
                      placeholder="Masukkan Tanggal Lahir"
                      name="dob"
                      :lang="lang"
                      :format="formats"
                      value-type="YYYY-MM-DD"
                      :disabled-date="disabledDate"
                    ></date-picker>
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="NPWP"
                  rules="min:20|max:20"
                >
                  <b-form-group label="NPWP" label-for="mc-name">
                    <b-form-input
                      id="npwp"
                      v-model="model.npwp"
                      v-mask="'##.###.###.#-###.###'"
                      placeholder="Masukan NPWP"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="No rekening"
                  :rules="''"
                >
                  <b-form-group>
                    <label label-for="no_rek">No Rekening </label>
                    <b-form-input
                      id="mc-name"
                      v-model="model.no_rek"
                      type="number"
                      placeholder="Masukkan No Rekening"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Pilih Regional"
                  rules="required"
                >
                  <b-form-group>
                    <label label-for="regional">
                      Pilih Regional <span class="fs-6 text-danger">*</span>
                    </label>
                    <v-select
                      id="regional"
                      v-model="model.regional"
                      :options="regionalLists"
                      :reduce="item => item.idwilayah"
                      label="nmwilayah"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Kantor asal"
                  rules="required"
                >
                  <b-form-group>
                    <label label-for="office">
                      Kantor Asal <span class="fs-6 text-danger">*</span>
                    </label>
                    <v-select
                      v-model="model.office"
                      :options="companies"
                      :reduce="item => `${item.namaktr}-${item.nopend}`"
                      label="namaktr"
                    />

                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Jabatan"
                  rules="required"
                >
                  <b-form-group>
                    <label>
                      Jabatan <span class="fs-6 text-danger">*</span>
                    </label>
                    <v-select
                      v-model="model.position"
                      :options="jabatan"
                      :reduce="item => item.name"
                      label="name"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="level"
                  rules="required"
                >
                  <b-form-group>
                    <label>
                      Level Kantor <span class="fs-6 text-danger">*</span>
                    </label>
                    <v-select
                      v-model="model.level"
                      :options="level"
                      :reduce="item => item.id"
                      label="name"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Jenis non pegawai"
                  rules="required"
                >
                  <b-form-group>
                    <label label-for="jenis_nonpegawai">
                      Jenis Non Pegawai <span class="fs-6 text-danger">*</span>
                    </label>
                    <v-select
                      v-model="model.type"
                      :options="jenis_nonpegawai"
                      :reduce="item => item.id"
                      label="name"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col md="6">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Pilih Nama Bagian"
                  rules="required"
                >
                  <b-form-group>
                    <label label-for="jenis_nonpegawai">
                      Bagian/Unit Kerja <span class="fs-6 text-danger">*</span>
                    </label>
                    <v-select
                      v-model="model.bagian"
                      :options="divisions"
                      :reduce="item => item.namabagian"
                      label="namabagian"
                    />
                    <p class="fs-6 text-danger">
                      {{ errors[0] }}
                    </p>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-card-footer>
              <!-- submit and reset -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mr-1 float-right"
              >
                Simpan
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-1 float-right"
                variant="outline-secondary"
                :to="{ name: 'manajemen-nonpegawai' }"
              >
                Batal
              </b-button>
            </b-card-footer>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
.vs__dropdown-toggle {
  min-width: 100% !important;
  max-width: 100% !important;
}
.v-select.vs--single .vs__selected {
  min-width: 100% !important;
  max-width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BCardFooter,
  BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'
import { mask } from 'vue-the-mask'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
    BAlert,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
    mask,
  },
  data() {
    return {
      name: 'UsersForm',
      errors: {
        nik: '',
        position: '',
        level: '',
      },
      showDismissibleAlert: false,
      config: {
        api: '/non-employees',
        types: '/misc/non-employee-types',
        regionals: '/misc/regionals',
        companies: '/misc/companies',
        jabatan: '/misc/positions',
        level: '/misc/levels',
        divisions: '/misc/bagian',
        redirect: 'manajemen-nonpegawai',
      },
      model: {
        nik: '',
        name: '',
        pob: '',
        dob: '',
        npwp: '',
        regional: userData?.regional,
        office: `${userData?.company}-${userData?.nopend}`,
        no_rek: '',
        type: '',
        position: '',
        level: '',
        bagian: '',
      },
      jabatan: [],
      level: [
        { id: 0, name: 'KANTOR PUSAT' },
        { id: 1, name: 'REGIONAL' },
        { id: 2, name: 'KCU/KC/MPC/SGLK' },
        { id: 3, name: 'KCP/DC/LE' },
        { id: 4, name: 'AGENPOS' },
      ],
      companies: [],
      regionalLists: [],
      divisions: [],
      confirmPass: null,
      jenis_nonpegawai: [],
      messages: '',
      formats: 'DD/MM/YYYY',
      lang: {
        days: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        months: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'Mei',
          'Jun',
          'Jul',
          'Agu',
          'Sep',
          'Oct',
          'Nov',
          'Dec',
        ],
        pickers: [
          'next 7 days',
          'next 30 days',
          'previous 7 days',
          'previous 30 days',
        ],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range',
        },
      },
      firstTimeGetCompanies: false,
    }
  },
  watch: {
    'model.regional': function (current, oldValue) {
      if (current !== oldValue && current && !this.firstTimeGetCompanies) {
        this.getCompaniesByRegional()
      }
    },
  },
  mounted() {
    this.getJenisNonPegawai()
    this.getJabatan()
    this.getRegionalList()
    this.getDivisions()
    if (this.model.regional && this.$route.meta.action === 'store') {
      this.firstTimeGetCompanies = true
      this.getCompaniesByRegional()
    }
    if (this.$route.meta.action != 'store') {
      this.getData()
    }
  },
  methods: {
    getJabatan() {
      axios.get(this.config.jabatan).then(response => {
        this.jabatan = response.data.data
      })
    },
    disabledDate(date) {
      return new Date() < date
    },
    getJenisNonPegawai() {
      axios.get(this.config.types).then(response => {
        this.jenis_nonpegawai = response.data.data
      })
    },
    getRegionalList() {
      axios.get(this.config.regionals).then(response => {
        this.regionalLists = response.data.data
      })
    },
    getDivisions() {
      axios.get(this.config.divisions).then(response => {
        this.divisions = response.data.data
      })
    },
    getCompaniesByRegional() {
      if (!this.firstTimeGetCompanies) {
        this.model = {
          ...this.model,
          office: '',
        }
      }
      axios
        .get(`${this.config.companies}/${this.model.regional}/regional`)
        .then(response => {
          this.companies = response.data.data
          if (this.firstTimeGetCompanies) {
            this.firstTimeGetCompanies = false
          }
        })
    },
    getData() {
      if (this.$route.meta.action == 'edit') {
        this.firstTimeGetCompanies = true
        axios
          .get(`${this.config.api}/${this.$route.params.id}`)
          .then(response => {
            const { data } = response.data
            this.model = {
              ...data,
              regional: data.regional || userData?.regional,
              level: +data.level,
            }
            this.getCompaniesByRegional()
          })
      }
    },
    save() {
      const _ = this
      const [office, nopend] = _.model.office.split('-')
      const data = {
        ..._.model,
        office,
        nopend: nopend || _.model.nopend,
      }
      if (this.$route.meta.action == 'store') {
        axios
          .post(_.config.api, data)
          .then(() => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: 'success',
                title: 'Tambah Data Berhasil',
                text: 'Data baru berhasil ditambahkan',
              },
            })
          })
          .catch(e => {
            // console.log(e.response.data.message.errors[0].msg);
            const vm = this
            vm.showDismissibleAlert = true
            if (typeof e.response.data.message === 'object') {
              vm.messages = e.response.data.message.errors[0].msg
              vm.errors = e.response.data.data
            } else {
              vm.messages = e.response.data.message
              vm.errors = e.response.data.data
            }
          })
      } else {
        data.id = _.$route.params.id
        axios
          .put(`${_.config.api}/${_.$route.params.id}`, data)
          .then(() => {
            _.$router.push({
              name: _.config.redirect,
              params: {
                event: 'success',
                title: 'Ubah Data Berhasil',
                text: 'Data berhasil diubah',
              },
            })
          })
          .catch(e => {
            const vm = this
            vm.showDismissibleAlert = true
            if (typeof e.response.data.message === 'object') {
              vm.messages = e.response.data.message.errors[0].msg
              vm.errors = e.response.data.data
            } else {
              vm.messages = e.response.data.message
              vm.errors = e.response.data.data
            }
          })
      }
    },
  },
}
</script>
