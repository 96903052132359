var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"title":this.$route.meta.title}},[_c('b-alert',{directives:[{name:"id",rawName:"v-id",value:(_vm.showDismissibleAlert == true),expression:"showDismissibleAlert == true"},{name:"height-fade",rawName:"v-height-fade"}],staticClass:"mb-2",attrs:{"dismissible":"","fade":"","variant":"danger"},model:{value:(_vm.showDismissibleAlert),callback:function ($$v) {_vm.showDismissibleAlert=$$v},expression:"showDismissibleAlert"}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v(_vm._s(_vm.messages))])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"No NIK KTP","rules":"required|numeric|digits:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"nik"}},[_vm._v(" No NIK KTP "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"mc-name","type":"number","placeholder":"Masukkan No NIK KTP"},model:{value:(_vm.model.nik),callback:function ($$v) {_vm.$set(_vm.model, "nik", $$v)},expression:"model.nik"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"name"}},[_vm._v(" Nama "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan Nama"},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Tempat lahir","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tempat Lahir","label-for":"mc-name"}},[_c('b-form-input',{attrs:{"id":"mc-name","placeholder":"Masukkan Tempat Lahir"},model:{value:(_vm.model.pob),callback:function ($$v) {_vm.$set(_vm.model, "pob", $$v)},expression:"model.pob"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Tanggal lahir","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"dob"}},[_vm._v("Tanggal Lahir ")]),_c('date-picker',{attrs:{"firstDayOfWeek":1,"placeholder":"Masukkan Tanggal Lahir","name":"dob","lang":_vm.lang,"format":_vm.formats,"value-type":"YYYY-MM-DD","disabled-date":_vm.disabledDate},model:{value:(_vm.model.dob),callback:function ($$v) {_vm.$set(_vm.model, "dob", $$v)},expression:"model.dob"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"NPWP","rules":"min:20|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NPWP","label-for":"mc-name"}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###.#-###.###'),expression:"'##.###.###.#-###.###'"}],attrs:{"id":"npwp","placeholder":"Masukan NPWP"},model:{value:(_vm.model.npwp),callback:function ($$v) {_vm.$set(_vm.model, "npwp", $$v)},expression:"model.npwp"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"No rekening","rules":''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"no_rek"}},[_vm._v("No Rekening ")]),_c('b-form-input',{attrs:{"id":"mc-name","type":"number","placeholder":"Masukkan No Rekening"},model:{value:(_vm.model.no_rek),callback:function ($$v) {_vm.$set(_vm.model, "no_rek", $$v)},expression:"model.no_rek"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Pilih Regional","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"regional"}},[_vm._v(" Pilih Regional "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"id":"regional","options":_vm.regionalLists,"reduce":function (item) { return item.idwilayah; },"label":"nmwilayah"},model:{value:(_vm.model.regional),callback:function ($$v) {_vm.$set(_vm.model, "regional", $$v)},expression:"model.regional"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Kantor asal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"office"}},[_vm._v(" Kantor Asal "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"options":_vm.companies,"reduce":function (item) { return ((item.namaktr) + "-" + (item.nopend)); },"label":"namaktr"},model:{value:(_vm.model.office),callback:function ($$v) {_vm.$set(_vm.model, "office", $$v)},expression:"model.office"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Jabatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v(" Jabatan "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"options":_vm.jabatan,"reduce":function (item) { return item.name; },"label":"name"},model:{value:(_vm.model.position),callback:function ($$v) {_vm.$set(_vm.model, "position", $$v)},expression:"model.position"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"level","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',[_vm._v(" Level Kantor "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"options":_vm.level,"reduce":function (item) { return item.id; },"label":"name"},model:{value:(_vm.model.level),callback:function ($$v) {_vm.$set(_vm.model, "level", $$v)},expression:"model.level"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Jenis non pegawai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"jenis_nonpegawai"}},[_vm._v(" Jenis Non Pegawai "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"options":_vm.jenis_nonpegawai,"reduce":function (item) { return item.id; },"label":"name"},model:{value:(_vm.model.type),callback:function ($$v) {_vm.$set(_vm.model, "type", $$v)},expression:"model.type"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Pilih Nama Bagian","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('label',{attrs:{"label-for":"jenis_nonpegawai"}},[_vm._v(" Bagian/Unit Kerja "),_c('span',{staticClass:"fs-6 text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"options":_vm.divisions,"reduce":function (item) { return item.namabagian; },"label":"namabagian"},model:{value:(_vm.model.bagian),callback:function ($$v) {_vm.$set(_vm.model, "bagian", $$v)},expression:"model.bagian"}}),_c('p',{staticClass:"fs-6 text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-card-footer',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Simpan ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"reset","variant":"outline-secondary","to":{ name: 'manajemen-nonpegawai' }}},[_vm._v(" Batal ")])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }